<template>
    <div id="market-header" class="d-flex-r-w-jsb-ac-gap10 p-10 xs-radius">
        <div class="item">
            <div class="label">
                حجم بازار
            </div>
            <div class="ticker">
                1,122,122
            </div>
        </div>
        <div class="item">
            <div class="label">
                بیشترین
            </div>
            <div class="ticker up">
                1,122,122
            </div>
        </div>
        <div class="item">
            <div class="label">
                کمترین
            </div>
            <div class="ticker down">
                1,122,122
            </div>
        </div>
        <div class="item">
            <div class="label">
                آخرین
            </div>
            <div class="ticker up">
                1,122,122
            </div>
        </div>
        <div class="item">
            <div class="label">
                تغییرات
            </div>
            <div dir="ltr" class="ticker up">
                +0.5%
            </div>
        </div>
        <market-header-drop-down/>
    </div>
</template>

<script>
    import MarketHeaderDropDown from "@/components/Panel/Market/MarketHeaderDropDown";
    export default {
        name: "MarketHeader",
        components: {MarketHeaderDropDown},
    }
</script>

<style lang="scss">
    #market-header {
        background-color: var(--gray-box);
        .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
        }
        .down{
            color: var(--red);
        }
        .up{
            color: var(--green);
        }
    }
</style>