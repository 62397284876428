<template>
    <div id="market" class="d-flex-r-w-ast-jsb-g1-gap10">
        <market-header class="fullWidth"/>
        <market-depth class="basis-250"/>
        <div class="d-flex-c-ast-jfs-gap10-g1 basis-400" style="flex-basis: 650px">
            <market-chart class="basis-400"/>
            <market-shop class="basis-400"/>
        </div>
        <div class="d-flex-c-ast-jfs-gap10-g1 basis-250">
            <market-live-price class="basis-100"/>
            <market-last-orders class="basis-400"/>
        </div>
        <market-orders class="fullWidth"/>
    </div>
</template>

<script>
    import MarketHeader from "@/components/Panel/Market/MarketHeader";
    import MarketDepth from "@/components/Panel/Market/MarketDepth";
    import MarketChart from "@/components/Panel/Market/MarketChart";
    import MarketShop from "@/components/Panel/Market/MarketShop/MarketShop";
    import MarketLivePrice from "@/components/Panel/Market/MarketLivePrice";
    import MarketLastOrders from "@/components/Panel/Market/MarketLastOrders";
    import MarketOrders from "@/components/Panel/Market/MarketOrders";

    export default {
        name: "Market",
        components: {MarketOrders, MarketLastOrders, MarketLivePrice, MarketShop, MarketChart, MarketDepth, MarketHeader},
        props: ['tradeTo', 'tradeFrom']
    }
</script>

<style lang="scss">
    #market {
        background-color: transparent !important;
        align-content: flex-start;
    }
</style>