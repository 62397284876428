<template>
    <the-table id="market-orders" :tabs="tabs" :columns="columns" :items="items">
        <template #cell(icon)>
            <span style="cursor: pointer" class="d-flex-r-jc-ac">
                <inline-svg width="21" :src="require(`@/assets/Icons/trash.svg`)"/>
            </span>
        </template>
    </the-table>
</template>

<script>
    import TheTable from "@/components/Tools/TheTable";

    export default {
        name: "MarketOrders",
        components: {TheTable},
        data() {
            return {
                tabs: [
                    {
                        name: 'sell',
                        label: 'سفارشات باز'
                    },
                    {
                        name: 'buy',
                        label: 'سفارشات قبلی'
                    },
                ],
                columns:[
                    {
                        name : 'createdAtDateTime',
                        label : 'تاریخ',
                        type : 'date',
                        dateType : 'faDateTime'
                    },
                    {
                        name : 'marketType',
                        label : 'بازار',
                        mobile : true
                    },
                    {
                        name : 'orderType',
                        label : 'نوع',
                        mobile : true
                    },
                    {
                        name : 'amount',
                        label : 'مقدار',
                        type: 'number',
                        mobile : true
                    },
                    {
                        name : 'unitPrice',
                        label : 'قیمت واحد',
                        type: 'number',
                    },
                    {
                        name : 'totalPrice',
                        label : 'قیمت کل',
                        type: 'number'
                    },
                    {
                        name : 'wagePercent',
                        label : 'انجام شده',
                        mobile : true
                    },
                    {
                        name : 'icon',
                        label : '',
                        mobile : true
                    },
                ],
                items:[
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'حد',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'BUY'
                    },
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'بازار',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'BUY'
                    },
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'حد',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'SELL'
                    },
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'بازار',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'SELL'
                    },
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'حد',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'SELL'
                    },
                    {
                        amount : 0.008,
                        createdAtDateTime : '2021-08-02T15:12',
                        marketType : 'بیتکوین - تومان',
                        orderType : 'بازار',
                        unitPrice : 1234567,
                        wagePercent : '0.4%',
                        totalPrice : 100165441,
                        receivedAmount : 123456,
                        type : 'SELL'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
#market-orders{
    background-color: var(--gray-box);
    th{
        background-color: var(--gray-box);
    }

}
</style>