<template>
    <div id="market-real-price" class="xs-radius px-10 pb-10 market-table">
        <h3 class="n-font p-10">بازار ها</h3>
        <table class="s-font">
            <tr>
                <th>رمز ارز</th>
                <th>قیمت</th>
                <th>تغییرات</th>
            </tr>
            <tr v-for="item in items" :key="item.relatedCoin" @click="$push({name : 'Market' , params:{tradeTo:tradeTo,tradeFrom:item.relatedCoin}})">
                <td>
                    <span class="d-flex-ac-jfs-gap10">
                        <img width="20" :src="require('@/assets/Icons/coins/'+item.relatedCoin+'.png')" alt=""/>
                        {{$coinLabel[item.relatedCoin]}}
                    </span>
                </td>
                <td>{{$toLocal(item.price)}}</td>
                <td dir="ltr">{{item.change}}%</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {tradeTo,tradeFrom} from "@/lib/reuseableFuncrtions";

    export default {
        name: "MarketLivePrice",
        data(){
            return{
                items: [
                    {
                        relatedCoin: 'BITCOIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'DOGE_COIN',
                        price: '1,451',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'ETHEREUM',
                        price: '45,415',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'CLASSIC_ETHEREUM',
                        price: '20,145',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'LITE_COIN',
                        price: '20,145',
                        change: 2.55,
                    },
                ]
            }
        },
        computed:{
            tradeTo,tradeFrom
        }
    }
</script>

<style lang="scss">
    #market-real-price {
        table{
            tr{
                &.buy{
                    background-image: linear-gradient(90deg,transparent var(--percent),var(--buy-hover) var(--percent));
                    td:last-of-type{
                        color: var(--green);
                    }
                }
                &.sell{
                    background-image: linear-gradient(90deg,transparent var(--percent),var(--red-hover) var(--percent));
                    td:last-of-type{
                        color: var(--red);
                    }
                }
                &.real-price{
                    color: var(--green);
                }
                &:hover{
                    td{
                        cursor: pointer;
                        &:first-of-type{
                            border-top-right-radius: var(--xs-radius);
                            border-bottom-right-radius: var(--xs-radius);
                        }
                        &:last-of-type{
                            border-top-left-radius: var(--xs-radius);
                            border-bottom-left-radius: var(--xs-radius);
                        }
                        background-color: var(--yellow-hover);
                    }
                }
            }
        }
    }
</style>