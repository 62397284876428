<template>
    <the-drop-down id="market-header-drop-down">
        <template #label>
            <div class="label d-flex-r-jsb-ac-gap15 grow-1">
                <span>
                    {{$coinLabel[tradeFrom]}} - {{$coinLabel[tradeTo]}}
                </span>
                <small class="gradient-template-gold-linear text-gradient">
                    بازار ها
                </small>
            </div>
        </template>
        <template #dropdown>
            <div class="card d-flex-c-ast-jfs">
                <div v-for="n in 4" :key="n" class="d-flex-ac-jsb item py-5 px-10"
                     @click="$push({name :'Market',params:{tradeFrom:items[n-1].relatedCoin,tradeTo:tradeTo}})">
                    <div>
                        {{$coinLabel[items[n-1].relatedCoin]}} - {{$coinLabel[tradeTo]}}
                    </div>
                    <b dir="ltr" :class="{'up' : n%2 , 'down' : n%2===0}">+0.5%</b>
                </div>
                <div class="d-flex-ac-jfs p-10">
                    <a class="d-flex-ac-jfs-gap5 gradient-template-gold-linear text-gradient fill"
                       :href="$href({name :'Market',params:{tradeFrom:tradeFrom,tradeTo:tradeTo==='TOMAN'? 'TETHER' : 'TOMAN'}})"
                       @click.prevent="$push({name :'Market',params:{tradeFrom:tradeFrom,tradeTo:tradeTo==='TOMAN'? 'TETHER' : 'TOMAN'}})"
                    >
                        {{tradeTo==='TOMAN'? 'بازار حرفه ای' : 'بازار اصلی'}}
                        <inline-svg height="10" :src="require('@/assets/Icons/arrow-left.svg')"/>
                    </a>
                </div>
            </div>
        </template>
    </the-drop-down>
</template>

<script>
    import TheDropDown from "@/components/Tools/TheDropDown";
    import {tradeFrom, tradeTo} from "@/lib/reuseableFuncrtions";

    export default {
        name: "MarketHeaderDropDown",
        components: {TheDropDown},
        data() {
            return {
                items: [
                    {
                        relatedCoin: 'BITCOIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'DOGE_COIN',
                        price: '1,451',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'ETHEREUM',
                        price: '45,415',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'TETHER',
                        price: '20,145',
                        change: 2.55,
                    },
                ]
            }
        },
        computed: {
            tradeFrom, tradeTo,
        }
    }
</script>

<style lang="scss">
    #market-header-drop-down {
        height: 50px;
        min-width: 220px;
        .dropdown-label {
            padding: 10px 15px;
            background-color: var(--dark);
            border-radius: var(--xs-radius);
            flex-grow: 1;
        }

        .dropdown {
            width: 100%;
            font-size: var(--m-font);

            .item {
                cursor: pointer;

                b {
                    &.up {
                        color: var(--green);
                    }

                    &.down {
                        color: var(--red);
                    }
                }
            }
        }
    }
</style>