<template>
    <div id="market-shop-form" class="m-radius m-font p-10 d-flex-r-w-gap10-g1 basis-250">
        <div class="market-shop-form-header d-flex-r-jsb-ac py-10 fullWidth">
            <div>
                {{typeLabel}} {{$coinLabel[tradeFrom]}}
            </div>
            <div dir="ltr" class="balance d-flex-r-ac-jfs-gap10">
                <inline-svg :src="require('@/assets/Icons/menu/wallets.svg')"/>
                {{cash}} {{$coinUnit[BUY ? tradeTo : tradeFrom]}}
            </div>
        </div>
        <form class="d-flex-c-ast-jsb-gap10 basis-120">
            <the-input align="right" placeholder="به قیمت" info="تومان" v-model="data.unitPrice"
                       @input="e => data.unitPrice = $toLocal(e)" :readonly="!limit"/>
            <the-input align="right" placeholder="مقدار" v-model="data.amount" @input="e => data.amount = $toLocal(e)"/>
            <the-input v-if="limit" align="right" placeholder="تاریخ انقضا" v-model="data.exp" @input="e => data.exp = $toLocal(e)"/>
            <span v-else/>
            <the-input align="right" placeholder="قیمت کل" info="تومان" v-model="data.totalPrice"
                       @input="e => data.totalPrice = $toLocal(e)" :readonly="true"/>
        </form>
        <div class="info d-flex-c-ast-jsb-g1-gap5 basis-100 s-font p-5">
            <p>
                پایین ترین پیشنهاد فروش
                31,374,189
            </p>
            <div dir="ltr" class="d-flex-r-jsb-ast info-radio s-radius">
                <label v-for="n in [0.25,0.5,0.75,1]" :key="n" class="d-flex-ast-jst">
                    <input type="radio" :value="n" hidden v-model="percent"/>
                    <span class="d-flex-ac-jc px-10 s-radius">
                        {{n*100}}%
                    </span>
                </label>
            </div>
            <span/>
            <span class="d-flex-ac-jfs mbn-5">
                کارمزد 0.4%
            </span>
        </div>
        <button class="fullWidth s-radius buy" :class="{buy : BUY,sell:!BUY}">
            {{typeLabel}} بیتکوین
        </button>
    </div>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    import {tradeFrom,tradeTo} from "@/lib/reuseableFuncrtions";

    const typeLabel= {
        buy : 'خرید',
        sell : 'فروش'
    }

    export default {
        name: "MarketShopForm",
        components: {TheInput},
        props:['limit','type','cash'],
        data() {
            return {
                data: {
                    unitPrice: '',
                    amount: '',
                    exp: '',
                    totalPrice: ''
                },
                percent: 0.25,
            }
        },
        computed:{
            tradeFrom,
            tradeTo,
            BUY(){
                return this.type==='buy'
            },
            typeLabel(){
                return typeLabel[this.type]
            }
        }
    }
</script>

<style lang="scss">
    #market-shop-form {
        background-color: var(--nav-back);

        form {
            >*{
                flex-basis: 35px;
            }
            font-size: 12px;
            input{
                font-size: 12px;
            }
        }

        > .info {
            background-color: var(--gray-box);
            text-align: right;
            >*{
                flex-basis: 35px;
            }
            .info-radio{
                background-color: var(--tooDark);
                font-size: var(--xs-font);
                border: 3px solid transparent;
                span{
                    cursor: pointer;
                }
                input:checked + span{
                    background-color: var(--yellow);
                    color: var(--dark);
                    font-weight: bold;
                    cursor: default;
                }
            }
        }

        button {
            height: 40px;
            color: var(--white);

            &.buy {
                background-color: var(--green);
            }

            &.sell {
                background-color: var(--red);
            }
        }
    }
</style>