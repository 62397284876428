<template>
    <div id="market-shop" class="xs-radius d-flex-r-w-afs-gap10-g1 p-10">
        <div class="market-shop-header d-flex-r-jsb-ac fullWidth py-10">
            <div class="basis-100">فرم سفارش</div>
            <div class="tabs d-flex-r-g1 basis-100 xs-radius" :style="{'--right' : limit ? '25%' : '75%'}">
                <div @click="limit=true" :class="{'selected' : limit}" class="basis-100">حد</div>
                <div @click="limit=false" :class="{'selected' : !limit}" class="basis-100">بازار</div>
            </div>
            <div class="basis-100"></div>
        </div>
        <market-shop-form :limit="limit" type="buy" cash="1,151,000"/>
        <market-shop-form :limit="limit" type="sell" cash="0.0045"/>
    </div>
</template>

<script>
    import MarketShopForm from "@/components/Panel/Market/MarketShop/MarketShopForm";
    export default {
        name: "MarketShop",
        components: {MarketShopForm},
        data(){
            return{
                limit:true,
            }
        }
    }
</script>

<style lang="scss">
    #market-shop {
        background-color: var(--gray-box);
        .market-shop-header{
            border-bottom: 1px solid var(--border);
            .tabs{
                background-color: var(--side-item);
                font-size: var(--m-font);
                --right:25%;
                > div{
                    width: 70px;
                    padding: 5px 0;
                    color: var(--side-color);
                    z-index: 1;
                    cursor: pointer;
                    &.selected{
                        cursor: default;
                    }
                }
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    width: 50%;
                    top: 0;
                    bottom: 0;
                    right: var(--right);
                    transform: translateX(50%);
                    background-color: var(--yellow1);
                    border-radius: inherit;
                    z-index: 0;
                    transition: right 0.3s;
                }
            }
        }
    }
</style>