var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-trading-view',{attrs:{"options":{
                            autosize:true,
                            symbol: 'BINANCE:' + _vm.$coinUnit[_vm.tradeFrom] + 'USDT',
                            timezone: 'Asia/Tehran',
                            theme: _vm.state.theme || 'dark',
                            library_path: '../assets/',
                            style: '1',
                            locale: 'fa_IR',
                            toolbar_bg: '#f1f3f6',
                            enable_publishing: false,
                            withdateranges: true,
                            range: '1D',
                            hide_side_toolbar: false,
                            allow_symbol_change: true,
                            toolbar_bg: '#fff',
                            loading_screen:{
                              backgroundColor : '#000',
                              foregroundColor : '#000'
                            },
                            studies_overrides: {
                                'volume.volume.color.0': '#f84960',
                                'volume.volume.color.1': '#01bf76',
                                'volume.volume.transparency': 75,
                                },
                            overrides:{
                                    'paneProperties.background': '#000',
                'paneProperties.vertGridProperties.color': 'rgba(83,87,96,0)',
                'paneProperties.horzGridProperties.color': 'rgba(54,60,78,0)',
                'mainSeriesProperties.areaStyle.color1': '#f8b200',
                'mainSeriesProperties.areaStyle.color2': '#f8b200',
                'mainSeriesProperties.areaStyle.linecolor': '#f8b200',
                'mainSeriesProperties.lineStyle.color': '#f8b200',
                'mainSeriesProperties.candleStyle.upColor': '#01bf76',
                'mainSeriesProperties.candleStyle.downColor': '#f84960',
                'mainSeriesProperties.candleStyle.borderColor': '#01bf76',
                'mainSeriesProperties.candleStyle.borderUpColor': '#01bf76',
                'mainSeriesProperties.candleStyle.borderDownColor': '#f84960',
                'mainSeriesProperties.candleStyle.wickUpColor': '#01bf76',
                'mainSeriesProperties.candleStyle.wickDownColor': '#f84960',
                'mainSeriesProperties.hollowCandleStyle.upColor': '#01bf76',
                'mainSeriesProperties.hollowCandleStyle.downColor': '#f84960',
                'mainSeriesProperties.hollowCandleStyle.borderColor': '#01bf76',
                'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#01bf76',
                'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#f84960',
                'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#01bf76',
                'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#f84960',
                'mainSeriesProperties.haStyle.upColor': '#01bf76',
                'mainSeriesProperties.haStyle.downColor': '#f84960',
                'mainSeriesProperties.haStyle.borderColor': '#01bf76',
                'mainSeriesProperties.haStyle.borderUpColor': '#01bf76',
                'mainSeriesProperties.haStyle.borderDownColor': '#f84960',
                'mainSeriesProperties.haStyle.wickUpColor': '#01bf76',
                'mainSeriesProperties.haStyle.wickDownColor': '#f84960',
                'mainSeriesProperties.barStyle.upColor': '#01bf76',
                'mainSeriesProperties.barStyle.downColor': '#f84960',
                'scalesProperties.backgroundColor':'#000'
                            },
                            custom_css_url: '../../assets/ali.css',
                             disabled_features: [
                                 'border_around_the_chart',
                                 'remove_library_container_border',
                                 'remove_container_border',
                                 'border_around_the_chart'
                                 ]
                            }}})}
var staticRenderFns = []

export { render, staticRenderFns }