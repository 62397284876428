<template>
    <div id="market-depth" class="xs-radius pb-10 market-table">
        <h3 class="n-font p-10">عمق بازار</h3>
        <table class="s-font">
            <tr>
                <th>مقدار</th>
                <th>قیمت واحد</th>
                <th>قیمت کل</th>
            </tr>
            <tr v-for="n in 9" :key="n" class="sell" :style="{'--percent' : Math.floor(Math.random() * 101) + '%'}">
                <td>0.003472</td>
                <td>139,123</td>
                <td>12,548,100</td>
            </tr>
            <tr class="real-price">
                <td colspan="3">
                    139,123.99
                </td>
            </tr>
            <tr v-for="n in 9" :key="-n" class="buy" :style="{'--percent' : Math.floor(Math.random() * 101) + '%'}">
                <td>0.003472</td>
                <td>139,123</td>
                <td>12,548,100</td>
            </tr>
            <tr>
                <th>مقدار</th>
                <th>قیمت واحد</th>
                <th>قیمت کل</th>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "MarketDepth"
    }
</script>

<style lang="scss">
    #market-depth {
        table{
            tr{

                &:last-of-type {
                    border-top: 1px solid var(--border);
                }
                &.buy{
                    background-image: linear-gradient(90deg,transparent var(--percent),var(--buy-hover) var(--percent));
                    td:last-of-type{
                        color: var(--green);
                    }
                }
                &.sell{
                    background-image: linear-gradient(90deg,transparent var(--percent),var(--red-hover) var(--percent));
                    td:last-of-type{
                        color: var(--red);
                    }
                }
                &.real-price{
                    color: var(--green);
                }
            }
        }
    }
</style>