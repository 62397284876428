<template>
    <div id="market-last-orders" class="xs-radius px-10 pb-10 market-table">
        <h3 class="n-font p-10">آخرین معاملات</h3>
        <table class="s-font">
            <tr>
                <th>قیمت</th>
                <th>مقدار</th>
                <th>زمان</th>
            </tr>
            <tr v-for="n in 10" :key="n">
                <td :style="{color:'var(--red)'}">139,123.24</td>
                <td>0.00457</td>
                <td>12:34</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "MarketLastOrders"
    }
</script>

<style lang="scss">

</style>